import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { IcreatecFooterComponent } from './footer.component';
import { CommonModule } from '@angular/common';


@NgModule({
    declarations: [
        IcreatecFooterComponent
    ],
    imports     : [
        RouterModule,
        CommonModule,
        TranslateModule
    ],
    exports     : [
        IcreatecFooterComponent
    ]
})
export class IcreatecFooterModule
{
}
