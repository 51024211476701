import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class WebAPIService {
  private mediaURL = 'http://api.icreatec.com/';
  private backendAPI = 'http://api.icreatec.com/v1';

  constructor(private http: HttpClient) { }

  getMediaUrl() {
    return this.mediaURL;
  }

  getSocialMedia()
  {
    return this.http.get(this.backendAPI + '/setting');
  }

  getCategorys() {
    return this.http.get(this.backendAPI + '/category');
  }

  getProduct(keyword: string, category: string) {
    return this.http.get(this.backendAPI + '/product/?search=' + keyword + '&category=' + category);
  }

  getNews() {
    return this.http.get(this.backendAPI + '/news');
  }
}
