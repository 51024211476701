import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit } from '@angular/core';
import { WebAPIService } from '../Service/web-api.service';

@Component({
    selector   : 'icreatec-footer',
    templateUrl: './footer.component.html',
    styleUrls  : ['./footer.component.scss']
})
export class IcreatecFooterComponent implements OnInit {
  SocialMedia: any = null;

  constructor(private webAPIService: WebAPIService, public translate: TranslateService) { }

  ngOnInit(): void {
    this.GetAllSocialMedia();
  }

  GetSocialMedia(name: string) {
    if (this.SocialMedia === null)
    {
      return null;
    }
    for (const sm of this.SocialMedia) {
      if (sm.key === name) {
        if (localStorage.getItem('currentLanguage') === 'zh-CN' && sm.value_zh !== null) {
          return sm.value_zh;
        }
        return sm.value;
      }
    }
    return null;
  }

  GetAllSocialMedia() {
    const request = this.webAPIService.getSocialMedia();
    request.subscribe((response) => {
      // console.log(response);
      const result = response['result'];

      this.SocialMedia = result;
    }, (error) => {
      console.log('Connection error');
    });
  }
}
