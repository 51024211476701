import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IcreatecNavbarComponent } from './navbar.component';
import { CommonModule } from '@angular/common';

@NgModule({
    declarations: [
        IcreatecNavbarComponent
    ],
    imports     : [
        RouterModule,
        CommonModule,
        TranslateModule
    ],
    exports     : [
        IcreatecNavbarComponent
    ]
})
export class IcreatecNavbarModule
{
}
