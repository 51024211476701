import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Icreatec';

  constructor(private translate: TranslateService) {
    const langs: Array<string> = ['en-US', 'zh-CN'];
    localStorage.setItem('languages', JSON.stringify(langs));
    console.log(localStorage.getItem('currentLanguage'));
    if (localStorage.getItem('currentLanguage') == null) {
      localStorage.setItem('currentLanguage', langs[0]);
    }
    translate.addLangs(langs);
    translate.setDefaultLang(langs[0]);
    translate.use(localStorage.getItem('currentLanguage'));

  }
}
