import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { IcreatecMainComponent } from './main.component';
import { IcreatecNavbarModule } from './navbar/navbar.module';
import { IcreatecFooterModule } from './footer/footer.module';
import { IcreatecContentModule } from './content/content.module';

@NgModule({
    declarations: [
        IcreatecMainComponent
    ],
    imports     : [
        RouterModule,
        IcreatecNavbarModule,
        IcreatecFooterModule,
        IcreatecContentModule,
    ],
    exports     : [
        IcreatecMainComponent
    ]
})
export class IcreatecMainModule
{
}
