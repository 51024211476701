import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IcreatecContentComponent } from './content.component';

const router: Routes = [
    {
        path: '',
        loadChildren: './pages/home/home.module#IcreatecHomeModule'
    },
    {
        path: 'demo',
        loadChildren: './pages/demo/demo.module#IcreatecDemoModule'
    },
    {
        path: 'home',
        loadChildren: './pages/home/home.module#IcreatecHomeModule'
    },
    {
        path: 'product',
        loadChildren: './pages/product/product.module#IcreatecProductModule'
    },
    {
        path: 'stem',
        loadChildren: './pages/stem/stem.module#StemModule'
    }
];
@NgModule({
    declarations: [
        IcreatecContentComponent
    ],
    imports     : [
        RouterModule.forChild(router),
        TranslateModule
    ],
    exports: [
        IcreatecContentComponent
    ]
})
export class IcreatecContentModule
{
}
