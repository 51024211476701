import { TranslateService } from '@ngx-translate/core';
import { element } from 'protractor';
import { Component, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { NavigationEnd, Router, ActivatedRoute } from '@angular/router';
import { type } from 'os';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';


@Component({
    selector     : 'icreatec-navbar',
    templateUrl  : './navbar.component.html',
    styleUrls    : ['./navbar.component.scss'],
    providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}],
    encapsulation: ViewEncapsulation.None
})
export class IcreatecNavbarComponent implements OnInit, OnDestroy, AfterViewInit {
  public static current: IcreatecNavbarComponent;
  public height: number;

  constructor(
      private router: Router,
      private activatedRoute: ActivatedRoute,
      private location: Location,
      public translate: TranslateService
  ) {
    IcreatecNavbarComponent.current = this;
  }

  switchLang(lang: string) {
    this.translate.use(lang);
    localStorage.setItem('currentLanguage', lang);
    window.location.reload();
  }

  isRoot(): boolean {
    return window.location.pathname === '/';
  }

  ngAfterViewInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      const init = params.init;
      // console.log(this);
      if (init) {
        setTimeout((event) => {
          IcreatecNavbarComponent.current.scroll(init);
        }, 750);
      }
    });
  }
  ngOnInit() {
    this.height = document.querySelector('.navbar > .container').clientHeight;
    setTimeout(() => {
      this.height = document.querySelector('.navbar > .container').clientHeight;
    }, 500);
  }

  ngOnDestroy() {

  }

  toggleBodyOverflow(enable: any) {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if (enable) {
      body.style.overflowY = 'auto';
      html.style.overflowY = 'auto';
    } else {
      body.style.overflowY = 'hidden';
      html.style.overflowY = 'hidden';
    }
  }

  scroll(el: string) {
    if (document.getElementById(el) !== null) {
      // console.log('srolling');

      const pos = document.getElementById(el).offsetTop - document.getElementsByClassName('navbar')[0].clientHeight;

      // window.scrollTo({
      //   top: pos,
      //   behavior: 'smooth'
      // });
      window.scroll({
        top: pos,
        behavior: 'smooth'
      });
    } else if (!this.isRoot()) {
      this.router.navigateByUrl('/?init=' + el);
    }
  }
}
