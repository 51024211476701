import { Component } from '@angular/core';


@Component({
    selector   : 'icreatec-content',
    templateUrl: './content.component.html',
    styleUrls  : ['./content.component.scss'],
})
export class IcreatecContentComponent
{

  constructor( ) { }

}
