import { Component, OnDestroy, ViewEncapsulation, HostBinding, Renderer2, ElementRef, Inject } from "@angular/core";
import { Subscription } from "rxjs";

@Component({
    selector        : 'icreatec-main',
    templateUrl     : './main.component.html',
    styleUrls       : ['./main.component.scss'],
    encapsulation   : ViewEncapsulation.None
})
export class IcreatecMainComponent implements OnDestroy
{

    constructor(
    )
    {

    }

    ngOnDestroy()
    {
    }


}